import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Spin, Table, Button, Modal, Form, Input, Select, Popconfirm, Tag, Tooltip} from "antd";
import AttributeThunk from "../../../redux/thunk/AttributeThunk";
import Toast from "../../../components/Notification/Toast";
import {FETCH_ATTRIBUTE_REJECTED,DELETE_ATTRIBUTE_FULFILLED,ADD_ATTRIBUTE_FULFILLED,UPDATE_ATTRIBUTE_FULFILLED} from "../../../redux/types/AttributeActionTypes";

const {Option} = Select;
const Attributes = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [editingAttribute, setEditingAttribute] = useState(null);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [searchedAttributes, setSearchAttributes] = useState([]);
    const [modalVisibleSelectoption, setModalVisibleSelectoption] = useState(false);
    const [modalOptions, setModalOptions] = useState([]);
    const {attributes, isLoading} = useSelector((state) => state.attributes);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            try {
                const payload = await dispatch(AttributeThunk.fetchAttributes(signal));
                await dispatch(AttributeThunk.attrbuteGroupList());
                if (payload.type === FETCH_ATTRIBUTE_REJECTED) {
                    Toast.errorNotification(payload.error.message);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            abortController.abort();
        };
    }, [dispatch]);

    useEffect(() => {
        setSearchAttributes([...attributes]);
    }, [attributes])


    const showModal = (options) => {
        setModalOptions(options);
        setModalVisibleSelectoption(true);
    };

    const handleModalCancelSelectoption = () => {
        setModalVisibleSelectoption(false);
    };


    const handleDelete = async (attributeId) => {
        const payload = await dispatch(AttributeThunk.deleteAttribute(attributeId));

        if (payload.type === DELETE_ATTRIBUTE_FULFILLED) {
            Toast.successNotification('Attribute deleted successfully');
            dispatch(AttributeThunk.attrbuteGroupList());
        } else {
            Toast.errorNotification(payload?.error?.message);
        }
    };

    const columns = [

        {
            title: "Name",
            dataIndex: "nameToShow",
            key: 'name',
            width: 150,
            className: "text_ellipes",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: 'type',
            className: "text_ellipes",
            width: 100,
        },
        {
            title: "Label",
            dataIndex: "label",
            key: 'label',
            className: "text_ellipes",
            width: 150,
        },
        {
            title: "Description",
            dataIndex: "description",
            key: 'description',
            className: "text_ellipes",
            width: 150,
        },
        {
            title: "Selected Option",
            dataIndex: "selectOption",
            key: 'selectOption',
            width: "150px",
            render: (selectOption, record) => (
                <div className={"attribute_badge_wrap"}>
                    {selectOption && selectOption.slice(0, 2).map((option, index) => (
                        <Tag className={"badge_icon"} key={index} color={index % 2 === 0 ? "" : ""}>
                            {option}
                        </Tag>
                    ))}
                    {selectOption && selectOption.length > 2 && (
                        <div className="more_items">
                            <Tooltip title="Show more options">
                                <span onClick={() => showModal(selectOption)}>+ {selectOption.length - 2} More...</span>
                            </Tooltip>
                        </div>
                    )}
                    {record?.type === 'boolean' && <>
                        <Tag className={"badge_icon"} key={1}>
                            True
                        </Tag>
                        <Tag className={"badge_icon"} key={2}>
                            False
                        </Tag>
                    </>}
                </div>
            ),
        },
        {
            title: "Actions",
            key: 'actions',
            width: "150px",
            fixed: 'right',
            render: (text, record) => (
                <div className={"btn_wrapper"}>
                    <Button type="secondary" className="icon_text_btn" onClick={() => showEditModal(record)}>
                        <span className={"icon"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M17.3047 6.81991C18.281 5.8436 18.281 4.26069 17.3047 3.28438L16.7155 2.69512C15.7391 1.71881 14.1562 1.71881 13.1799 2.69512L3.69097 12.1841C3.34624 12.5288 3.10982 12.9668 3.01082 13.4442L2.34111 16.6735C2.21932 17.2607 2.73906 17.7805 3.32629 17.6587L6.55565 16.989C7.03302 16.89 7.47103 16.6536 7.81577 16.3089L17.3047 6.81991ZM16.1262 4.46289L15.5369 3.87363C15.2115 3.5482 14.6839 3.5482 14.3584 3.87363L13.4745 4.75755L15.2423 6.52531L16.1262 5.6414C16.4516 5.31596 16.4516 4.78833 16.1262 4.46289ZM14.0638 7.70382L12.296 5.93606L4.86948 13.3626C4.75457 13.4775 4.67577 13.6235 4.64277 13.7826L4.23082 15.769L6.21721 15.3571C6.37634 15.3241 6.52234 15.2453 6.63726 15.1303L14.0638 7.70382Z"
                                            fill="#667085"/>
                                    </svg>
                        </span>
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this attribute?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                        overlayClassName={"custom_popover_wraper"}
                    >
                        <Button className="icon_text_btn" type="danger">
                      <span className={"icon"}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                fill="none">
                            <path
                                d="M8.33317 8.12484C8.79341 8.12484 9.1665 8.49793 9.1665 8.95817V13.9582C9.1665 14.4184 8.79341 14.7915 8.33317 14.7915C7.87293 14.7915 7.49984 14.4184 7.49984 13.9582V8.95817C7.49984 8.49793 7.87293 8.12484 8.33317 8.12484Z"
                                fill="#667085"/>
                            <path
                                d="M12.4998 8.95817C12.4998 8.49793 12.1267 8.12484 11.6665 8.12484C11.2063 8.12484 10.8332 8.49793 10.8332 8.95817V13.9582C10.8332 14.4184 11.2063 14.7915 11.6665 14.7915C12.1267 14.7915 12.4998 14.4184 12.4998 13.9582V8.95817Z"
                                fill="#667085"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M14.9998 4.99984V4.1665C14.9998 2.78579 13.8806 1.6665 12.4998 1.6665H7.49984C6.11913 1.6665 4.99984 2.78579 4.99984 4.1665V4.99984H3.74984C3.2896 4.99984 2.9165 5.37293 2.9165 5.83317C2.9165 6.29341 3.2896 6.6665 3.74984 6.6665H4.1665V15.8332C4.1665 17.2139 5.28579 18.3332 6.6665 18.3332H13.3332C14.7139 18.3332 15.8332 17.2139 15.8332 15.8332V6.6665H16.2498C16.7101 6.6665 17.0832 6.29341 17.0832 5.83317C17.0832 5.37293 16.7101 4.99984 16.2498 4.99984H14.9998ZM12.4998 3.33317H7.49984C7.0396 3.33317 6.6665 3.70627 6.6665 4.1665V4.99984H13.3332V4.1665C13.3332 3.70627 12.9601 3.33317 12.4998 3.33317ZM14.1665 6.6665H5.83317V15.8332C5.83317 16.2934 6.20627 16.6665 6.6665 16.6665H13.3332C13.7934 16.6665 14.1665 16.2934 14.1665 15.8332V6.6665Z"
                                  fill="#667085"/>
                            </svg>
                       </span>
                            Delete</Button>

                    </Popconfirm>
                </div>
            ),
        },
    ];


    const showAddModal = () => {
        form.resetFields();
        setIsAddModalVisible(true);
    };

    const handleAdd = async () => {
        try {
            const values = await form.validateFields();
            const payload = await dispatch(AttributeThunk.addAttribute(values));
            if (payload.type === ADD_ATTRIBUTE_FULFILLED) {
                setIsAddModalVisible(false);
                form.resetFields();
                Toast.successNotification("Attribute added successfully");
            } else {
                Toast.errorNotification(payload?.error?.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    // const addModalFormLayout = {
    //     labelCol: {span: 6},
    //     wrapperCol: {span: 14},
    // };

    const renderDynamicFields = (type) => {
        if (type === "select" || type === "dropdown") {
            return (
                <>
                    <Form.Item
                        label="Add Options"
                        name="selectOption"
                        rules={[
                            {type: "array", message: "Please select options!"},
                        ]}
                    >
                        <Select placeholder="Enter options"
                                mode={type === "dropdown" || type === "select" ? "tags" : "default"}>
                            {(form.getFieldValue("selectOption") || []).map((opt, index) => (
                                <Option key={index} value={opt}>
                                    {opt}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                </>
            );
        } else if (type === "multiselect") {
            return (
                <Form.Item
                    label="Options"
                    name="selectOption"
                    rules={[
                        {type: "array", message: "Please select options!"},
                    ]}
                >
                    <Select mode="tags">
                        {(form.getFieldValue("selectOption") || []).map((opt, index) => (
                            <Option key={index} value={opt}>
                                {opt}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            );

        }
        return null;
    };


    const handleTypeChange = (value) => {
        // Reset options when type changes
        form.setFieldsValue({
            selectOption: [],
        });
    };
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const showEditModal = (record) => {
        setEditingAttribute(record);
        form.setFieldsValue(record);
        setIsEditModalVisible(true);
    };

    const handleEdit = async () => {
        try {
            const values = await form.validateFields();
            values.attributeId = editingAttribute.id;
            const payload = await dispatch(
                AttributeThunk.updateAttribute(values)
            );

            if (payload.type === UPDATE_ATTRIBUTE_FULFILLED) {
                setIsEditModalVisible(false);
                Toast.successNotification("Attribute updated successfully");
                form.resetFields();
                // dispatch(AttributeThunk.fetchAttributes());
            } else {
                Toast.errorNotification(payload.error.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    const handleSearch = (val) => {
        let value = val.target.value;
        let temp = attributes.filter(v => v.nameToShow.toLowerCase().includes(value.toLowerCase()));
        setSearchAttributes([...temp]);
    }

    return (
        <div className={"attribute_list_wrapper"}>
            <div className={"attribute_list_header column_view"}>
                <div className={"top_sec"}>
                    <h2>Attributes List </h2>
                    {/*<Button type="primary" className="primary_btn" onClick={showAddModal}>Add Attribute</Button>*/}
                    <div className="right_btn_wrap">
                        <div className="search_box border_ui" style={{marginRight: '10px'}}>
                            <input type="text"
                                   placeholder="Search"
                                   onChange={handleSearch}
                            />
                            <span className="search_icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                  <path
                                      d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                                      stroke="#969696" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <Button type="primary" className="primary_btn" onClick={showAddModal}>
                            Add Attribute
                        </Button>
                    </div>
                </div>
                <div className="total_result">
                    <strong className={"total_count"}>Total results:</strong> <span
                    className={"count_text"}>({attributes?.length}) {attributes?.length > 1 ? "Attributes" : "Attribute"}</span>
                </div>
            </div>
            <div className={"custom_table_ui"}>
                <Spin spinning={isLoading && !attributes.length}>
                    <Table
                        dataSource={[...searchedAttributes.filter(val=>val.name==='price' || val.name==='product_name' || val.name==='sku' || val.name==='parent_sku' || val.name==='taxonomy_categories' || val.name==='brand_name'),...searchedAttributes.filter(val=>!(val.name==='price' || val.name==='product_name' || val.name==='sku' || val.name==='parent_sku' || val.name==='taxonomy_categories' || val.name==='brand_name'))]}
                        columns={columns}
                        rowKey="name"
                        className={"attribute_table_list fixed_layout"}
                        scroll={{
                            x: 1200,
                        }}
                        pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "75", "100"],
                        }}
                    />
                </Spin>
            </div>


            <Modal
                title="Add Attribute"
                open={isAddModalVisible}
                onOk={handleAdd}
                onCancel={() => setIsAddModalVisible(false)}
                className={"custom_modal"}
                footer={[
                    <Button className={"secondary_btn"}
                            onClick={() => setIsAddModalVisible(false)}
                            key="1">Cancel</Button>,
                    <Button className={"primary_btn"}
                            onClick={handleAdd}
                            key="2">Save</Button>,
                ]}
            >
                <Form
                    form={form}
                    initialValues={{type: 'text'}}
                    layout="vertical"
                    className={"custom_form_area"}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{required: true, message: 'Please enter a name!'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[{required: true, message: 'Please select a type!'}]}
                    >
                        <Select className={"single_select"}
                                onChange={handleTypeChange}
                        >
                            <Option value="text">Text</Option>
                            <Option value="number">Number</Option>
                            <Option value="dropdown">Dropdown</Option>
                            {/* <Option value="select">Select</Option> */}
                            <Option value="multiselect">Multiselect</Option>
                            <Option value="date">Date</Option>
                            <Option value="boolean">Boolean</Option>
                            <Option value="media">Media</Option>
                            <Option value="url">URL</Option>
                            <Option value="html">HTML</Option>
                            {/* <Option value="completion">Completion</Option> */}

                            {/* Add other options based on your requirement
                            s */}
                        </Select>
                    </Form.Item>
                    {renderDynamicFields(form.getFieldValue('type'))}

                    <Form.Item
                        label="Label"
                        name="label"
                        // rules={[{required: true, message: 'Please enter a label!'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        // rules={[{required: true, message: 'Please enter a description!'}]}
                    >
                        <Input/>
                    </Form.Item>

                </Form>
            </Modal>

            {/* Add Edit Modal */}
            <Modal
                title="Edit Attribute"
                open={isEditModalVisible}
                onOk={handleEdit}
                onCancel={() => {
                    setIsEditModalVisible(false);
                    setEditingAttribute(null);
                    setEditingAttribute(null);
                    form.resetFields();


                }}
                className={"custom_modal"}
                footer={[
                    <Button className={"primary_btn"} key="2" onClick={() => {
                        setIsEditModalVisible(false);
                        setEditingAttribute(null);
                        form.resetFields();
                    }}>Cancel</Button>,
                    <Button className={"secondary_btn"} onClick={handleEdit} key="1">Save</Button>,

                ]}

            >
                <Form
                    form={form}
                    initialValues={{type: "text"}}
                    layout="vertical"
                    className={"custom_form_area"}
                >
                    <Form.Item label="Name" name="name">
                        <Input disabled={true}/>
                    </Form.Item>
                    <Form.Item label="Type" name="type">
                        <Input disabled={true}/>
                    </Form.Item>
                    {renderDynamicFields(form.getFieldValue("type"), true)}
                    <Form.Item label="Label" name="label">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="All Options"
                open={modalVisibleSelectoption}
                onCancel={handleModalCancelSelectoption}
                footer={null}
                className={"custom_modal more_attribute_listing"}
            >
                <div className={"attribute_inner_listing"}>
                    {modalOptions.map((option, index) => (
                        <div className={"attribute_badge_wrap"} key={index}>
                            <Tag className={"badge_icon"} color={index % 2 === 0 ? "" : ""}>
                                {option}
                            </Tag>
                        </div>
                    ))}
                </div>
                <div className={"modal_footer"}></div>
            </Modal>
        </div>
    );
};

export default Attributes;
